<template>
  <div class="tw-max-w-[60%]">
    <SearchMap
      ref="map"
      :geo-search="geoSearch"
      @drawn="drawn"
      @selected="selectedCities"
    />
  </div>
</template>

<script>
import SearchMap from '@/components/contacts/hmodh/SearchMap'

export default {
  name: 'PropertySearchMap',
  components: {
    SearchMap
  },
  data () {
    return {
      geoSearch: []
    }
  },
  methods: {
    show () {
      this.$refs.map.render()
    },
    clear () {
      this.$refs.map.clear()
    },
    drawn () {
      this.$nextTick(() => {
        const coordinates = this.$refs.map.getCoordinates()
        this.$emit('mapDrawn', coordinates)
      })
    },
    selectedCities (cities) {
      this.$emit('mapSelectedCities', cities)
    },
    setCitiesOnMap (childCities) {
      /**
         * The url holds all child cities, but the map holds parent cities. For example:
         * Dikmuide + deelgemeenten is a parent city and Beerst or Leke is a child city.
         *
         * Whenever we update the cities, we fetch all child cities specified in the form and determine their parent cities
         * if any. We then populate the map with parent cities + the cities that do not have a parent city.
         *
         * If the parent's city is a custom type (e.g. province) then use the city itself
         * Else use the parent city
      **/

      const parentCities = childCities
        .map(childCity => childCity.parent?.type === 3 || !childCity.parent ? childCity : childCity.parent)
        .reduce((parentCities, parentCity) => {
          const parentCityIds = parentCities.map(city => city.id)
          if (parentCityIds.includes(parentCity.id)) {
            return parentCities
          } else {
            return parentCities.concat([parentCity])
          }
        }, [])

      this.$nextTick(() => {
        parentCities.forEach(city => {
          const citiesOnMap = this.$refs.map.cities
          const citiesOnMapIds = citiesOnMap.map(city => city.id)
          if (!citiesOnMapIds.includes(city.id)) {
            this.$refs.map.cities.push(city)
            this.$refs.map.selectedFeatures.push(city.id)
          }
        })
      })
    }
  }
}
</script>
