<template>
  <FormulateForm
    #default="{ isLoading }"
    :key="key"
    v-model="values"
    name="publicationActionCreate"
    invalid-message="Gelieve de verplichte velden correct in te vullen."
    @submit="createPubAction"
  >
    <Modal ref="modal" title="Pubactie aanmaken">
      <details open>
        <summary class="link tw-font-semibold tw-text-sm">De pubactie zal worden aangemaakt voor:</summary>
        <div class="tw-m-3 tw-font-bold">
          <span v-for="(property, index) in properties" :key="property.id">
            <router-link :to="{ name: 'PropertyDetails', params: { id: property.id } }" target="_blank">{{ property.reference }}</router-link>
            <span v-if="index !== properties.length - 1"> | </span>
          </span>
          <span v-for="(project, index) in projects" :key="project.id">
            <router-link :to="{ name: 'ProjectDetails', params: { id: project.id } }" target="_blank">{{ project.reference }}</router-link>
            <span v-if="index !== projects.length - 1"> | </span>
          </span>
        </div>
      </details>
      <FormulateInput
        type="date"
        name="start_date"
        label="Startdatum"
        placeholder="YYYY-MM-DD"
        :show-required-label="true"
        validation="bail|required|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Startdatum mag niet na de einddatum liggen.',
        }"
      />
      <FormulateInput
        type="date"
        name="end_date"
        label="Einddatum"
        placeholder="YYYY-MM-DD"
        validation="bail|optional|date:YYYY-MM-DD|validEndDate"
        :validation-rules="{ validEndDate }"
        :validation-messages="{
          validEndDate:
            'Einddatum kan niet voor de startdatum vallen.',
        }"
      />
      <FormulateInput
        :options="publicationActionOptions"
        name="type"
        label="Pubactietype"
        type="select"
        placeholder="Kies een pubactie"
        validation="required"
        :show-required-label="true"
      />
      <FormulateInput
        type="textarea"
        name="custom_description_value"
        label="Extra tekst"
      />
      <template #footer>
        <FormulateErrors />
        <div class="tw-flex tw-flex-row tw-space-x-4 tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            :input-class="['tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            help-position="before"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-check',
              ]"
            />
            Actie opslaan
          </FormulateInput>
          <FormulateInput
            type="button"
            :input-class="['tw-bg-error tw-w-full']"
            :outer-class="['tw-w-full md:tw-w-auto']"
            @click="hide"
          >
            <i class="far fa-times tw-mr-2" /> Annuleren
          </FormulateInput>
        </div>
      </template>
    </Modal>
  </FormulateForm>
</template>

<script>
import Modal from '@/components/iam/Modal'
import { startLoadingModal, successModal, errorModal } from '@/modalMessages'
import { chunks, validEndDate } from '@/utils/helpers'
import { createPropertyPublicationAction, getPublicationActionTypes } from '@/services/properties'
import { createProjectPublicationAction } from '@/services/projects'

export default {
  name: 'PublicationActionCreateModal',
  components: {
    Modal
  },
  props: {
    properties: {
      type: Array,
      default () {
        return []
      }
    },
    projects: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      values: {},
      key: 1,
      publicationActionTypes: []
    }
  },
  watch: {
    publicationAction (values) {
      const { start_date, end_date, custom_description_value } = values
      this.values = { start_date, end_date, custom_description_value }
    }
  },
  methods: {
    validEndDate,
    async show () {
      const response = await getPublicationActionTypes()
      this.publicationActionTypes = response.data?.results
      this.$refs.modal.show()
    },
    hide () {
      this.values = {}
      this.$refs.modal.hide()
    },
    async createPubAction (data) {
      try {
        startLoadingModal('Pub actie registreren')
        let entity = null

        if (this.properties?.length) {
          entity = {
            type: 'property',
            records: this.properties,
            noun: this.properties.length === 1 ? 'pand' : 'panden'
          }
        } else if (this.projects?.length) {
          entity = {
            type: 'project',
            records: this.projects,
            noun: this.projects.length === 1 ? 'project' : 'projecten'
          }
        } else return errorModal('Geen entiteit geselecteerd, probeer het opnieuw.')

        const response = await chunks(
          entity.records,
          record => {
            if (entity.type === 'property') {
              return createPropertyPublicationAction(record.id, { property: record.id, ...this.values })
            } else {
              return createProjectPublicationAction(record.id, { ...this.values })
            }
          },
          50
        )
        this.key = Math.random()
        this.hide()
        successModal(`Pubactie(s) geregistreerd voor ${entity.records.length} ${entity.noun}`, true)
        return response
      } catch (error) {
        console.error(error)
        errorModal('Pubactie kon niet worden geregistreerd. Probeer later opnieuw.')
      }
    }
  },
  computed: {
    publicationActionOptions () {
      let options = []
      if (this.publicationActionTypes.length) {
        options = this.publicationActionTypes.map(type => {
          return { value: type.id, label: type.name_nl }
        })
      }
      return options
    }
  }
}
</script>
