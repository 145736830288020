<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <fieldset class="fieldset-split-range">
      <legend>Perceeloppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_plot_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_plot_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Laadkaaien</legend>
      <FormulateInput
        type="number"
        name="loading_docks_min"
        min="0"
        placeholder="Min"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="loading_docks_max"
        min="0"
        placeholder="Max"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Handelsoppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_trading_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_trading_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Vrije hoogte</legend>
      <FormulateInput
        type="number"
        name="free_height_min"
        min="0"
        placeholder="Min"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="free_height_max"
        min="0"
        placeholder="Max"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Bruikbare opslagruimte</legend>
      <FormulateInput
        type="number"
        name="usable_storage_space_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="usable_storage_space_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>kVa</legend>
      <FormulateInput
        type="number"
        name="kva_min"
        min="0"
        placeholder="Min"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="kva_max"
        min="0"
        placeholder="Max"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Bruikbare kantoorruimte</legend>
      <FormulateInput
        type="number"
        name="usable_office_space_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="usable_office_space_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <div>
      <FormulateInput
        type="checkbox"
        name="has_light_street"
        label="Lichtstraat"
      />
      <FormulateInput
        type="checkbox"
        name="has_rolling_bridge"
        label="Rolbrug"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'PropertySearchCommercial'
}
</script>
