<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <fieldset class="fieldset-split-range">
      <legend>Badkamers</legend>
      <FormulateInput
        type="number"
        name="bathroom_count_min"
        min="0"
        placeholder="Min"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="bathroom_count_max"
        min="0"
        placeholder="Max"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Perceeloppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_plot_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_plot_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Bouwjaar</legend>
      <FormulateInput
        type="number"
        name="build_year_min"
        min="0"
        placeholder="Min (YYYY)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="build_year_max"
        min="0"
        placeholder="Max (YYYY)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Bewoonbare oppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_livable_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_livable_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Renovatiejaar</legend>
      <FormulateInput
        type="number"
        name="renovation_year_min"
        min="0"
        placeholder="Min (YYYY)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="renovation_year_max"
        min="0"
        placeholder="Max (YYYY)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Bebouwde oppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_built_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_built_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Verdieping</legend>
      <FormulateInput
        type="number"
        name="floor_min"
        min="0"
        placeholder="Min"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="floor_max"
        min="0"
        placeholder="Max"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Handelsoppervlakte</legend>
      <FormulateInput
        type="number"
        name="surface_trading_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="surface_trading_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Terrasoppervlakte</legend>
      <FormulateInput
        type="number"
        name="terrace_area_min"
        min="0"
        placeholder="Min (m²)"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="number"
        name="terrace_area_max"
        min="0"
        placeholder="Max (m²)"
        outer-class="tw-m-0"
      />
    </fieldset>
    <FormulateInput
      type="checkbox"
      name="building_type"
      label="Bebouwing"
      :options="{ 1: 'Open', 2: 'Halfopen', 3: 'Gesloten' }"
      input-class="tw-my-2"
    />
  </div>
</template>

<script>
export default {
  name: 'PropertySearchIndeling'
}
</script>

<style scoped>
/* To make the building_type checkbox options inline */
/deep/ div[role="group"] {
  @apply tw-flex tw-flex-wrap tw-gap-x-4;
}
</style>
