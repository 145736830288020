<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <FormulateInput
      type="autocomplete"
      auto-complete-type="office"
      :multiple="true"
      name="office"
      label="Kantoor"
      placeholder="Selecteer kantoren"
    />
    <FormulateInput
      type="select"
      name="concept_type"
      label="Concept"
      :options="$options.conceptTypes"
    />
    <FormulateInput
      type="autocomplete"
      auto-complete-type="collaborator"
      :multiple="true"
      :params="{ include_out_of_service: 1 }"
      name="collaborator"
      label="Medewerker"
      placeholder="Selecteer medewerkers"
    >
      <template #label="{ label, id, classes }">
        <div class="tw-flex tw-justify-between">
          <label :for="id" :class="classes.label" v-text="label" />
          <button
            type="button"
            class="link tw-font-semibold"
            @click="listeners['selectMyself'](collaborator)"
          >
            Mezelf selecteren
          </button>
        </div>
      </template>
    </FormulateInput>
    <FormulateInput
      type="autocomplete"
      auto-complete-type="group"
      :multiple="true"
      name="groups"
      label="Groep"
      placeholder="Selecteer groepen"
    />
    <fieldset class="fieldset-split-range">
      <legend>Huur startdatum</legend>
      <FormulateInput
        type="date"
        name="rental_start_date_after"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="date"
        name="rental_start_date_before"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Huur einddatum</legend>
      <FormulateInput
        type="date"
        name="rental_end_date_after"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="date"
        name="rental_end_date_before"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
    </fieldset>
    <fieldset class="fieldset-split-range">
      <legend>Aktedatum</legend>
      <FormulateInput
        type="date"
        name="deed_date_after"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
      <FormulateInput
        type="date"
        name="deed_date_before"
        validation="bail|optional|date:YYYY-MM-DD"
        outer-class="tw-m-0"
      />
    </fieldset>
    <FormulateInput
      type="date"
      name="created_on_after"
      label="Dossier aangemaakt vanaf"
      outer-class="tw-my-2"
    />
  </div>
</template>

<script>
export default {
  name: 'PropertySearchDossier',
  conceptTypes: [
    { value: '', label: 'Alle' },
    { value: 0, label: 'Classic' },
    { value: 1, label: 'Pre-sale' },
    { value: 3, label: 'Rent to Buy' },
    { value: 4, label: 'Publieke veiling' },
    { value: 5, label: 'Power-sale' },
    { value: 6, label: 'Do it yourself' }
  ]
}
</script>
