var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FormulateForm',{key:_vm.key,attrs:{"name":"publicationActionCreate","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.createPubAction},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('Modal',{ref:"modal",attrs:{"title":"Pubactie aanmaken"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('FormulateErrors'),_c('div',{staticClass:"tw-flex tw-flex-row tw-space-x-4 tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto'],"help-position":"before"}},[_c('i',{class:[
              'fas tw-mr-1',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-check' ]}),_vm._v(" Actie opslaan ")]),_c('FormulateInput',{attrs:{"type":"button","input-class":['tw-bg-error tw-w-full'],"outer-class":['tw-w-full md:tw-w-auto']},on:{"click":_vm.hide}},[_c('i',{staticClass:"far fa-times tw-mr-2"}),_vm._v(" Annuleren ")])],1)]},proxy:true}],null,true)},[_c('details',{attrs:{"open":""}},[_c('summary',{staticClass:"link tw-font-semibold tw-text-sm"},[_vm._v("De pubactie zal worden aangemaakt voor:")]),_c('div',{staticClass:"tw-m-3 tw-font-bold"},[_vm._l((_vm.properties),function(property,index){return _c('span',{key:property.id},[_c('router-link',{attrs:{"to":{ name: 'PropertyDetails', params: { id: property.id } },"target":"_blank"}},[_vm._v(_vm._s(property.reference))]),(index !== _vm.properties.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()],1)}),_vm._l((_vm.projects),function(project,index){return _c('span',{key:project.id},[_c('router-link',{attrs:{"to":{ name: 'ProjectDetails', params: { id: project.id } },"target":"_blank"}},[_vm._v(_vm._s(project.reference))]),(index !== _vm.projects.length - 1)?_c('span',[_vm._v(" | ")]):_vm._e()],1)})],2)]),_c('FormulateInput',{attrs:{"type":"date","name":"start_date","label":"Startdatum","placeholder":"YYYY-MM-DD","show-required-label":true,"validation":"bail|required|date:YYYY-MM-DD|validEndDate","validation-rules":{ validEndDate: _vm.validEndDate },"validation-messages":{
        validEndDate:
          'Startdatum mag niet na de einddatum liggen.',
      }}}),_c('FormulateInput',{attrs:{"type":"date","name":"end_date","label":"Einddatum","placeholder":"YYYY-MM-DD","validation":"bail|optional|date:YYYY-MM-DD|validEndDate","validation-rules":{ validEndDate: _vm.validEndDate },"validation-messages":{
        validEndDate:
          'Einddatum kan niet voor de startdatum vallen.',
      }}}),_c('FormulateInput',{attrs:{"options":_vm.publicationActionOptions,"name":"type","label":"Pubactietype","type":"select","placeholder":"Kies een pubactie","validation":"required","show-required-label":true}}),_c('FormulateInput',{attrs:{"type":"textarea","name":"custom_description_value","label":"Extra tekst"}})],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})}
var staticRenderFns = []

export { render, staticRenderFns }