<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <FormulateInput
      type="checkbox"
      name="is_investment_property"
      label="Investeringsvastgoed"
    />
    <FormulateInput
      type="checkbox"
      name="is_rented"
      label="Momenteel verhuurd"
    />
    <FormulateInput
      type="checkbox"
      name="is_sale_with_shares"
      label="Verkoop onder aandelen"
    />
    <FormulateInput
      type="checkbox"
      name="is_ready_to_go"
      label="Instapklaar"
    />
    <FormulateInput
      type="number"
      name="return_on_investment_min"
      label="Minimum rendement na aankoopkosten"
      placeholder="Min (%)"
    />
    <FormulateInput
      type="select"
      name="property_age_group"
      label="Leeftijd pand"
      placeholder="Selecteer een optie"
      :options="$options.propertyAgeOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'PropertySearchInvestment',
  propertyAgeOptions: [
    { value: 0, label: 'Minder dan 10 jaar oud' },
    { value: 1, label: 'Minstens 10 jaar oud en maximaal 20 jaar oud' },
    { value: 2, label: 'Meer dan 20 jaar oud' }
  ]
}
</script>
