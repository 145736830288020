<template functional>
  <div class="md:tw-grid md:tw-grid-cols-2 tw-gap-x-6">
    <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
      <FormulateInput
        v-for="(label, key) in $options.checkboxes"
        :key="key"
        type="checkbox"
        :name="key"
        :label="label"
        outer-class="tw-m-0"
      />
    </div>
    <FormulateInput
      type="select"
      name="sea_view"
      label="Zeezicht"
      placeholder="Selecteer een optie met zeezicht"
      :options="$options.seaViewOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'PropertySearchComfort',
  checkboxes: {
    has_swimming_pool: 'Zwembad',
    is_furnished: 'Gemeubeld',
    has_bike_storage: 'Fietsenberging',
    has_pets_allowed: 'Huisdieren toegelaten',
    has_elevator: 'Lift',
    has_garage: 'Garage'
  },
  seaViewOptions: [
    { value: 0, label: 'Alles' },
    { value: 1, label: 'Ja' },
    { value: 2, label: 'Nee' },
    { value: 3, label: 'Ja, maar geen zijdelings' }
  ]
}
</script>
